import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { Icon, Alert } from '@mui/material';
import useAlert from '../../hooks/useAlert';

export default function CustomSnackbar() {
  const [open, setOpen] = React.useState(false);

  const { message, variant, showAlert } = useAlert();

  React.useEffect(() => {
    console.log(message, variant);
    setOpen(Boolean(message));
  }, [message, variant]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    showAlert('');
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Icon>close</Icon>
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        open={open && Boolean(message)}
        autoHideDuration={5000}
        onClose={handleClose}
        action={action}
      >
        <Alert onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

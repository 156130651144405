/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint no-underscore-dangle: 0 */
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Avatar,
  Theme,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, {
  ReactNode,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import SlidingPanel from 'react-sliding-side-panel';
import {
  QCNewLogo,
  SubscriberIcon,
  ReferralsNewIcon,
  ReferrersIcon,
} from '../../../assets/svg';
import 'react-sliding-side-panel/lib/index.css';
import { logout } from '../../../store/common/common.reducer';
import styles from './layout.module.scss';
import { AuthService } from '../../services/auth/auth.service';
import ENVIRONMENTS from '../../../environments';
import Notifications from '../notifications/notifications.component';
import { getProfileImageUrl } from '../../helpers/helpers';
import { useUserData } from '../../contexts/user-data.context';
import { UserPlaceholder } from '../../../assets/images';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profileImage: {
      color: theme.palette.background.default,
      backgroundColor: grey[700],
    },
  }),
);

function Layout(
  props: PropsWithChildren<RouteComponentProps<ReactNode>>,
): JSX.Element {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { user } = useUserData();
  const { children, history } = props;
  const [open, setOpen] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const anchorRef = useRef<any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [openDashboard, setOpenDashboard] = React.useState(false);
  const [openHelp, setOpenHelp] = React.useState(false);
  const [openCalculator, setOpenCalculator] = React.useState(false);
  const [windowSize, setWindowSize] = React.useState(0);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  const handleClick = () => {
    setOpenDashboard(!openDashboard);
  };

  const handleClickCalculator = () => {
    setOpenCalculator(!openCalculator);
  };

  const handleClickHelp = () => {
    setOpenHelp(!openHelp);
  };

  const handleToggle = () => {
    setOpen((prevOpen: boolean) => !prevOpen);
  };

  const logoutHandle = async () => {
    const redirectUri = localStorage.getItem('redirect_uri');
    try {
      handleToggle();
      await new AuthService().logout();
      dispatch(logout());
    } finally {
      dispatch(logout());
      localStorage.removeItem('refferingChannel');
      if (redirectUri?.endsWith('://app')) {
        window.location.href = redirectUri;
      } else {
        history.push('/');
      }
    }
  };

  const handleClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  function closePanel() {
    setOpenPanel(false);
  }

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const performLogout = async (event: any) => {
    handleClose(event);
    try {
      await new AuthService().logout();
      window.location.href = ENVIRONMENTS.KEYCLOAK_REDIRECT_URI;
    } finally {
      dispatch(logout());
    }
  };

  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Grid className={styles.layout} container direction="column">
      <Grid item className={styles.header} direction="row">
        {user && (
          <IconButton
            className={styles.iconContainer}
            onClick={() => setOpenPanel(true)}
            size="large"
          >
            <span className="material-icons-outlined">menu</span>
          </IconButton>
        )}
        <Link to="/">
          <p className={styles.heading}>
            <img src={QCNewLogo} alt="QuickCamLogoHeader" width="36" />
            QuickCam
          </p>
        </Link>
        <div className={styles.actions}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 20 }}>
              <Notifications />
            </div>
            <Avatar
              className={`${classes.profileImage} ${styles.profileImage}`}
              src={
                getProfileImageUrl(user?.profileImageURL || '') ||
                `${UserPlaceholder}`
              }
              onClick={handleToggle}
              ref={anchorRef}
            >
              {(
                `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}` ||
                user?.channelName?.charAt(0) ||
                ''
              ).toUpperCase()}
            </Avatar>
          </div>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="account-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem style={{ textTransform: 'none' }}>
                        @{user?.channelName}
                      </MenuItem>
                      <hr />
                      <MenuItem
                        onClick={() => {
                          history.push('/u/settings');
                          setOpen(false);
                        }}
                      >
                        Edit Profile
                      </MenuItem>
                      <hr />
                      <MenuItem onClick={logoutHandle}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Grid>
      <Grid
        item
        direction="column"
        // className={styles.content}
      >
        {children}
      </Grid>
      <SlidingPanel
        type="left"
        isOpen={openPanel}
        size={75}
        backdropClicked={() => closePanel()}
      >
        <div className={styles.sidePanelContainer}>
          <div className={styles.sidePanelHeader}>
            <Link
              to="/u/dashboard"
              className={styles.sidePanelHeading}
              onClick={() => {
                closePanel();
              }}
            >
              <img src={QCNewLogo} alt="QuickCamLogoHeader" width="50" />
              QuickCam
            </Link>
          </div>
          <div className={styles.sidePanelLinks}>
            <ListItem
              className={styles.listItem}
              selected={selectedIndex === 0}
            >
              <ListItemIcon>
                <span className={`material-icons ${styles.iconMaterialDesign}`}>
                  dashboard
                </span>
              </ListItemIcon>
              <ListItemText
                onClick={(event: any) => {
                  handleListItemClick(event, 0);
                  setSelectedIndex(0);
                  closePanel();
                  history.push('/u/dashboard');
                }}
                primary="Dashboard"
              />
              {/* {!openDashboard ? (
                <span onClick={handleClick} className="material-icons">
                  expand_more
                </span>
              ) : (
                <span onClick={handleClick} className="material-icons">
                  expand_less
                </span>
              )} */}
            </ListItem>
            {/* <Collapse in={openDashboard} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={styles.subMenu}>
                <ListItem
                  className={styles.listItem}
                  button
                  selected={selectedIndex === 41}
                  component={Link}
                  to="/u/connections/referrals"
                  onClick={(event: any) => {
                    closePanel();
                    handleListItemClick(event, 41);
                  }}
                >
                  <span
                    style={{ width: '30px' }}
                    className={`material-icons ${styles.iconMaterialDesign}`}
                  >
                    <img src={ReferralsNewIcon} alt="referralsNewIcon" />
                  </span>
                  <ListItemText primary="Referrals" />
                </ListItem>
                <ListItem
                  className={styles.listItem}
                  button
                  selected={selectedIndex === 42}
                  component={Link}
                  to="/u/connections/referrers"
                  onClick={(event: any) => {
                    closePanel();
                    handleListItemClick(event, 42);
                  }}
                >
                  <span
                    style={{ width: '30px' }}
                    className={`material-icons ${styles.iconMaterialDesign}`}
                  >
                    <img src={ReferrersIcon} alt="referrersIcon" />
                  </span>
                  <ListItemText primary="Referring Channels" />
                </ListItem>
                <ListItem
                  className={styles.listItem}
                  button
                  selected={selectedIndex === 43}
                  component={Link}
                  to="/u/connections/subscribers"
                  onClick={(event: any) => {
                    closePanel();
                    handleListItemClick(event, 43);
                  }}
                >
                  <span
                    style={{ width: '30px' }}
                    className={`material-icons ${styles.iconMaterialDesign}`}
                  >
                    <img src={SubscriberIcon} alt="subscriberIcon" />
                  </span>
                  <ListItemText primary="subscribers" />
                </ListItem>
                <ListItem
                  className={styles.listItem}
                  button
                  selected={selectedIndex === 44}
                  component={Link}
                  to="/u/connections/favorites"
                  onClick={(event: any) => {
                    closePanel();
                    handleListItemClick(event, 44);
                  }}
                >
                  <span
                    style={{ width: '30px' }}
                    className={`material-icons ${styles.iconMaterialDesign}`}
                  >
                    favorite_border
                  </span>
                  <ListItemText primary="Favorites" />
                </ListItem>
              </List>
            </Collapse> */}

            {/* Share */}
            {/* <ListItem
              className={styles.listItem}
              button
              selected={selectedIndex === 3}
              component={Link}
              to="/u/share"
              onClick={(event: any) => {
                closePanel();
                handleListItemClick(event, 3);
              }}
            >
              <ListItemIcon>
                <span className={`material-icons ${styles.iconMaterialDesign}`}>
                  share
                </span>
              </ListItemIcon>
              <ListItemText primary="Share" />
            </ListItem> */}

            {/* Announcements */}
            {windowSize < 768 && (
              <ListItem
                className={styles.listItem}
                button
                selected={selectedIndex === 4}
                component={Link}
                type="smallHide"
                to="/u/announcements"
                onClick={(event: any) => {
                  closePanel();
                  handleListItemClick(event, 4);
                }}
                style={{ minWidth: '40px !important' }}
              >
                <ListItemIcon style={{ minWidth: '40px !important' }}>
                  <span
                    className={`material-icons ${styles.iconMaterialDesign}`}
                  >
                    campaign
                  </span>
                </ListItemIcon>
                <ListItemText primary="Announcements" />
              </ListItem>
            )}

            {/* Notifications */}
            {windowSize < 768 && (
              <ListItem
                className={styles.listItem}
                button
                type="smallHide"
                selected={selectedIndex === 5}
                component={Link}
                to="/u/notification"
                onClick={(event: any) => {
                  closePanel();
                  handleListItemClick(event, 5);
                }}
              >
                <ListItemIcon>
                  <span
                    className={`material-icons ${styles.iconMaterialDesign}`}
                  >
                    notifications
                  </span>
                </ListItemIcon>
                <ListItemText primary="Notifications" />
              </ListItem>
            )}
            {/* Downloads */}
            <ListItem
              className={styles.listItem}
              button
              selected={selectedIndex === 6}
              component={Link}
              to="/u/downloads"
              onClick={(event: any) => {
                closePanel();
                handleListItemClick(event, 6);
              }}
            >
              <ListItemIcon>
                <span className={`material-icons ${styles.iconMaterialDesign}`}>
                  file_download
                </span>
              </ListItemIcon>
              <ListItemText primary="Downloads" />
            </ListItem>

            {/* <ListItem
              button
              className={styles.listItem}
              selected={selectedIndex === 30}
              onClick={event => {
                handleClickCalculator();
                handleListItemClick(event, 30);
              }}
            >
              <ListItemIcon>
                <span className={`material-icons ${styles.iconMaterialDesign}`}>
                  calculate
                </span>
              </ListItemIcon>
              <ListItemText primary="Calculator" />
              {!openCalculator ? (
                <span className="material-icons">expand_more</span>
              ) : (
                <span className="material-icons">expand_less</span>
              )}
            </ListItem> */}
            {/* <Collapse in={openCalculator} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={styles.subMenu}>
                <ListItem
                  className={styles.listItem}
                  component={Link}
                  to="/u/calculator/p-followers"
                  button
                  selected={selectedIndex === 31}
                  onClick={(event: any) => {
                    handleListItemClick(event, 31);
                    closePanel();
                  }}
                >
                  <ListItemIcon>
                    <span
                      style={{ width: '30px' }}
                      className={`material-icons ${styles.iconMaterialDesign}`}
                    >
                      calculate
                    </span>
                  </ListItemIcon>
                  <ListItemText primary="Follower Goal Calculator" />
                </ListItem>
                {window.location.pathname !== 'quickcam.app' && (
                  <ListItem
                    className={styles.listItem}
                    button
                    selected={selectedIndex === 32}
                    component={Link}
                    to="/u/calculator/p-income-2"
                    onClick={(event: any) => {
                      closePanel();
                      handleListItemClick(event, 32);
                    }}
                  >
                    <ListItemIcon>
                      <span
                        style={{ width: '30px' }}
                        className={`material-icons ${styles.iconMaterialDesign}`}
                      >
                        calculate
                      </span>
                    </ListItemIcon>
                    <ListItemText primary="Income Goal Calculator" />
                  </ListItem>
                )}
              </List>
            </Collapse> */}

            {/* Help */}
            <ListItem
              button
              className={styles.listItem}
              selected={selectedIndex === 14}
              onClick={event => {
                handleClickHelp();
                handleListItemClick(event, 14);
              }}
            >
              <ListItemIcon>
                <span className={`material-icons ${styles.iconMaterialDesign}`}>
                  help
                </span>
              </ListItemIcon>
              <ListItemText primary="Help" />
              {!openHelp ? (
                <span className="material-icons">expand_more</span>
              ) : (
                <span className="material-icons">expand_less</span>
              )}
            </ListItem>
            <Collapse in={openHelp} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={styles.subMenu}>
                {/* <ListItem
                  className={styles.listItem}
                  button
                  selected={selectedIndex === 5}
                  component={Link}
                  to="/u/help/quick_start"
                  onClick={() => {
                    closePanel();
                  }}
                >
                  <ListItemIcon>
                    <span
                      style={{ width: '30px' }}
                      className={`material-icons ${styles.iconMaterialDesign}`}
                    >
                      start
                    </span>
                  </ListItemIcon>
                  <ListItemText primary="Quick Start" />
                </ListItem> */}
                {/* <ListItem
                  className={styles.listItem}
                  button
                  selected={selectedIndex === 33}
                  component={Link}
                  to="/u/help/faqs"
                  onClick={(event: any) => {
                    closePanel();
                    handleListItemClick(event, 33);
                  }}
                >
                  <ListItemIcon>
                    <span
                      style={{ width: '30px' }}
                      className={`material-icons ${styles.iconMaterialDesign}`}
                    >
                      quiz
                    </span>
                  </ListItemIcon>
                  <ListItemText primary="FAQs" />
                </ListItem> */}
                <ListItem
                  className={styles.listItem}
                  button
                  selected={selectedIndex === 34}
                  component={Link}
                  to="/u/help/tutorials"
                  onClick={(event: any) => {
                    closePanel();
                    handleListItemClick(event, 34);
                  }}
                >
                  <ListItemIcon>
                    <span
                      style={{ width: '30px' }}
                      className={`material-icons ${styles.iconMaterialDesign}`}
                    >
                      home_repair_service
                    </span>
                  </ListItemIcon>
                  <ListItemText primary="Tutorials" />
                </ListItem>
              </List>
            </Collapse>

            {/* Subscription */}
            {/* <ListItem
              className={styles.listItem}
              button
              selected={selectedIndex === 6}
              component={Link}
              to="/u/subscription"
              onClick={() => {
                closePanel();
              }}
            >
              <ListItemIcon>
                <span className={`material-icons ${styles.iconMaterialDesign}`}>
                  paid
                </span>
              </ListItemIcon>
              <ListItemText primary="Subscription" />
            </ListItem> */}

            {/* <ListItem
              className={styles.listItem}
              button
              selected={selectedIndex === 7}
              component={Link}
              to="/u/payout"
              onClick={(event: any) => {
                closePanel();
                handleListItemClick(event, 7);
              }}
            >
              <ListItemIcon>
                <span className={`material-icons ${styles.iconMaterialDesign}`}>
                  payment
                </span>
              </ListItemIcon>
              <ListItemText primary="Payout" />
            </ListItem> */}

            {/* <ListItem
              className={styles.listItem}
              button
              selected={selectedIndex === 8}
              component={Link}
              to="/u/commissions"
              onClick={(event: any) => {
                closePanel();
                handleListItemClick(event, 8);
              }}
            >
              <ListItemIcon>
                <span className={`material-icons ${styles.iconMaterialDesign}`}>
                  payment
                </span>
              </ListItemIcon>
              <ListItemText primary="Commissions" />
            </ListItem> */}

            <ListItem
              className={styles.listItem}
              button
              selected={selectedIndex === 9}
              component={Link}
              to="/u/settings"
              onClick={(event: any) => {
                closePanel();
                handleListItemClick(event, 9);
              }}
            >
              <ListItemIcon>
                <span className={`material-icons ${styles.iconMaterialDesign}`}>
                  settings
                </span>
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem
              className={styles.listItem}
              button
              selected={selectedIndex === 10}
              onClick={(event: React.MouseEvent<EventTarget, MouseEvent>) => {
                closePanel();
                performLogout(event);
              }}
            >
              <ListItemIcon>
                <span className={`material-icons ${styles.iconMaterialDesign}`}>
                  logout
                </span>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </div>
        </div>
      </SlidingPanel>
      <section className={styles.footer}>
        <span>
          <p>
            <Link to="/about-us">About us</Link>
          </p>
          <p>|</p>
          <p>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
          <p>|</p>
          <p>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </p>
          <p>|</p>
          <p>
            <Link to="/terms-and-conditions">Terms of Service</Link>
          </p>
          <p>|</p>
          <p>
            <Link to="/trust-policy">Trust Policy</Link>
          </p>
          <p className={styles.hidden}>|</p>
        </span>
        <p>&copy; Copyright 2023 QuickCam, LLC. All rights reserved.</p>
      </section>
    </Grid>
  );
}

export default withRouter(Layout);

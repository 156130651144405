/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, memo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Alert,
  Snackbar,
} from '@mui/material';
import ENVIRONMENTS from '../../../environments';
import styles from './cookie-popup.module.scss';

function CookiePopUp(): JSX.Element | null {
  const baseUrl = ENVIRONMENTS.APPLICATION_DOMAIN;
  const [isCookiesAccepted, setIsCookiesAccepted] = useState(true);
  const [toggleSellPI, setToggleSellPI] = useState(false);
  const [updateSellPI, setUpdateSellPI] = useState(false);

  useEffect(() => {
    setIsCookiesAccepted(localStorage.getItem('isCookiesAccepted') === 'true');
  }, []);

  const setCookies = () => {
    setIsCookiesAccepted(true);
    localStorage.setItem('isCookiesAccepted', 'true');
  };
  const toggleSellInformation = () => {
    localStorage.setItem('isPersonalInformationSell', 'false');
    setToggleSellPI(!toggleSellPI);
  };
  const toggleSellInformationConfirm = () => {
    setUpdateSellPI(true);
    localStorage.setItem('isPersonalInformationSell', 'true');
    setToggleSellPI(!toggleSellPI);
  };
  if (isCookiesAccepted) return null;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={`material-icons-outlined ${styles.cookieIcon}`}>
          cookie
        </span>

        <h4>Your privacy</h4>
      </div>
      <p>
        We and selected third parties collect personal information. <br />
        In case of sale of your personal information, you may opt out by using
        the link{' '}
        <Link onClick={toggleSellInformation} className={styles.linkClass}>
          Do Not Sell My Personal Information.
        </Link>
        <br />
        To find you more about the categories of personal information collected
        and the purposes for which such information will be used, please refer
        to our <a href="/privacy-policy">Privacy Policy.</a>
      </p>
      <Snackbar
        open={updateSellPI}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setUpdateSellPI(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Updated!
        </Alert>
      </Snackbar>
      <Dialog
        open={toggleSellPI}
        fullWidth
        maxWidth="md"
        onClose={toggleSellInformation}
      >
        <DialogContent>
          <h5>Do Not Sell My Personal Information.</h5>
          <br />
          <p>Do you really want to opt out?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleSellInformationConfirm}>Confirm</Button>
          <Button onClick={toggleSellInformation}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <h4>Cookie Consent</h4>
      <p>
        By clicking “Cookie Consent”, you agree QuickCam can store cookies on
        your device and disclose information in accordance with our{' '}
        <a
          href={`${baseUrl}/cookie-policy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Cookie Policy.
        </a>
      </p>
      <div>
        <Button
          variant="contained"
          className={styles.actionBtn}
          onClick={setCookies}
        >
          Cookie Consent
        </Button>
      </div>
    </div>
  );
}

export default memo(CookiePopUp);

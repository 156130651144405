/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { SocialBadge } from '../../../assets/images';
import {
  EarlyAdopter,
  EarlyAdopterUnSubscribed,
  FoundingMemberBadge,
  FoundingMemberBadgeUnSubscribed,
  PreLaunchMemberBadge,
  PreLaunchMemberBadgeUnSubscribed,
  QuickStarterBadge,
  QuickStartGuide,
  QuickStartGuideInCompleted,
} from '../../../assets/svg';
import {
  Badges,
  IBadge,
  BadgeService,
  BADGES_SOURCE,
} from '../../services/badges/badge.service';
import UserBadgeComponent from './user-badge.component';

interface IProps {
  freeTrialDay?: number;
  badgeSize?: 'small' | 'large' | 'xSmall';
  style?: any;
  badge: IBadge;
  from?: any;
  badgeClass?: string;
}

function getBadgePath(badge: IBadge) {
  switch (badge?.badge?.code) {
    case Badges.FOUNDING_MEMBER:
      return {
        src:
          badge?.meta?.color === 'silver'
            ? FoundingMemberBadgeUnSubscribed
            : FoundingMemberBadge,
      };
    case Badges.PRELAUNCH:
      return {
        src:
          badge?.meta?.color === 'silver'
            ? PreLaunchMemberBadgeUnSubscribed
            : PreLaunchMemberBadge,
      };
    case Badges.SOCIAL_MEDIA_CONNECTION:
      return { src: SocialBadge };
    case Badges.EARLY_ADOPTER:
      return {
        src:
          badge?.meta?.color === 'silver'
            ? EarlyAdopterUnSubscribed
            : EarlyAdopter,
      };
    case Badges.QUICKSTART_GUIDE:
      return {
        src:
          badge?.meta?.color === 'silver'
            ? QuickStartGuideInCompleted
            : QuickStartGuide,
      };
    case Badges.QUICK_STARTER:
      return {
        src: QuickStarterBadge,
      };
    case Badges.EARLY_SUBSCRIBER_IOS:
      return {
        src: BADGES_SOURCE[Badges.EARLY_SUBSCRIBER_IOS][
          badge?.meta?.color ?? 'gold'
        ][badge?.meta?.freeTrialDay || 1],
      };
    case Badges.EARLY_SUBSCRIBER_ANDROID:
      return {
        src: BADGES_SOURCE[Badges.EARLY_SUBSCRIBER_ANDROID][
          badge?.meta?.color ?? 'gold'
        ][badge?.meta?.freeTrialDay || 1],
      };
    case Badges.EMAIL_INVITER:
      return {
        src: BADGES_SOURCE[Badges.EMAIL_INVITER][
          badge?.meta?.variant ?? 'invite'
        ],
      };
    case Badges.TEXT_INVITER:
      return {
        src: BADGES_SOURCE[Badges.TEXT_INVITER][
          badge?.meta?.variant ?? 'invite'
        ],
      };
    case Badges.QR_CODE_INVITER:
      return {
        src: BADGES_SOURCE[Badges.QR_CODE_INVITER][
          badge?.meta?.variant ?? 'invite'
        ],
      };
    case Badges.DM_INVITER:
      return {
        src: BADGES_SOURCE[Badges.DM_INVITER][badge?.meta?.variant ?? 'invite'],
      };
    default: {
      return new BadgeService().getFreeTrialDayForParticularBadge(
        badge?.badge?.code,
        badge,
      );
    }
  }
}

function BadgeRenderer(props: IProps): JSX.Element | null {
  const { badge, ...otherProps } = props;
  const { src, freeTrialDay } = getBadgePath(badge);
  if (!src) {
    return null;
  }
  return (
    <UserBadgeComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      src={src}
      freeTrialDay={freeTrialDay || otherProps?.freeTrialDay}
    />
  );
}

BadgeRenderer.defaultProps = {
  freeTrialDay: null,
  badgeSize: 'small',
  style: {},
  from: null,
  badgeClass: '',
};

export default BadgeRenderer;

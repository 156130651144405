/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import { useWindowSize } from '../../../app/utils/utils.common';
import styles from './user-badge.module.scss';

interface IProps {
  src: string;
  freeTrialDay?: number;
  badgeSize?: 'small' | 'large' | 'xSmall';
  style?: any;
  from?: any;
  badgeClass?: string;
}

const BADGE_SIZE: any = {
  small: '40px',
  large: '64px',
  xSmall: '30px',
};

const MOBILE_BADGE_SIZE: any = {
  small: '45px',
  large: '45px',
  xSmall: '30px',
};

function Badge(props: IProps): JSX.Element | null {
  const { src, freeTrialDay, badgeSize, style, from, badgeClass } = props;
  const size = useWindowSize();

  return (
    <div className={[styles.badgeWrapper, badgeClass].join(' ')}>
      <img
        alt="badge"
        src={src}
        style={{
          height:
            size.width < 450
              ? MOBILE_BADGE_SIZE[badgeSize || 'small']
              : BADGE_SIZE[badgeSize || 'small'],
          ...style,
        }}
      />
      {freeTrialDay ? (
        <div
          className={styles?.freeTrialCount}
          style={from === 'referral' ? { top: '40%' } : {}}
        >
          {freeTrialDay}
        </div>
      ) : (
        []
      )}
    </div>
  );
}

Badge.defaultProps = {
  freeTrialDay: null,
  badgeSize: 'small',
  style: {},
  from: null,
  badgeClass: '',
};

export default memo(Badge);

import { grey } from '@mui/material/colors';
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Avatar,
  MenuItem,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { logout } from '../../../store/common/common.reducer';
import { AuthService } from '../../services/auth/auth.service';
import { IUserData } from '../../services/user/user-data.interface';
import styles from './account-control.module.scss';
import { getProfileImageUrl } from '../../helpers/helpers';

interface Props {
  user?: IUserData;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profileImage: {
      color: theme.palette.background.default,
      backgroundColor: grey[700],
    },
  }),
);

function AccountControl(props: Props) {
  const dispatch = useDispatch();
  const { user } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const history = useHistory();

  const handleToggle = () => {
    setOpen((prevOpen: boolean) => !prevOpen);
  };

  const logoutHandle = async () => {
    try {
      handleToggle();
      await new AuthService().logout();
    } finally {
      dispatch(logout());
      history.push('/');
    }
  };
  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Avatar
          className={`${classes.profileImage} ${styles.profileImage}`}
          src={getProfileImageUrl(user?.profileImageURL || '')}
          onClick={handleToggle}
          ref={anchorRef}
        >
          {(
            `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}` ||
            user?.channelName?.charAt(0) ||
            ''
          ).toUpperCase()}
        </Avatar>
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        className={styles.menuListWrapepr}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="account-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem style={{ textTransform: 'none' }}>
                    @{user?.channelName}
                  </MenuItem>
                  {user?.privateDisplayName ? (
                    <MenuItem>{user?.privateDisplayName}</MenuItem>
                  ) : null}
                  <hr style={{ margin: 0 }} />
                  <MenuItem
                    onClick={() => {
                      history.push('/u/settings');
                    }}
                  >
                    Edit Profile
                  </MenuItem>
                  <hr style={{ margin: 0 }} />
                  <MenuItem onClick={logoutHandle}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

AccountControl.defaultProps = {
  user: {},
};

export default AccountControl;
